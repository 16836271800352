<script setup lang="ts">
import type { PropType } from 'vue'
import type { InputColor, InputSize } from '#ui/types'
import {
	parsePhoneNumber,
	getCountryCallingCode,
	getExampleNumber,
	isValidPhoneNumber,
	type CountryCode,
} from 'libphonenumber-js'
import examples from 'libphonenumber-js/mobile/examples'
import 'lodash'
import lodash from 'lodash'

const props = defineProps({
	modelValue: {
		type: undefined as unknown as PropType<string | undefined>,
		required: true
	},
	country: {
		type: String as PropType<CountryCode>,
		default: 'RU',
	},
	size: {
		type: String as PropType<InputSize>,
		default: 'sm'
	},
	color: {
		type: String as PropType<InputColor>,
		default: undefined
	},
	autofocus: {
		type: Boolean,
		default: false,
	},
	ui: {
		type: Object,
		default: () => ({})
	},
})

const emits = defineEmits([
	'update:modelValue'
])

const localValue = computed({
	get: () => props.modelValue,
	set: (value) => emits('update:modelValue', value)
})

const country = props.country
const countryCode = '+' + getCountryCallingCode(country)

const uiLocal = lodash.extend({
	leading: {
		padding: {
			sm: 'ps-8',
			xl: 'ps-10'
		}
	},
	icon: {
		leading: {
			padding: {
				sm: 'text-sm',
			}
		}
	}
}, props.ui)

const cleanPhoneCode = (phone: string | undefined) => {
	if (!phone) {
		return
	}
	let tmp = phone.trim().replace(countryCode, '')
	if (tmp.startsWith('8')) {
		tmp = tmp.substring(1)
	}
	return tmp.trim()
}

const exampleNumber = parsePhoneNumber(getExampleNumber(country, examples)!.number, country)

const placeholder = cleanPhoneCode(exampleNumber.formatInternational())

const local = ref(cleanPhoneCode(localValue.value))

watch(() => local.value, (phone) => {
	let phoneClean = cleanPhoneCode(phone)
	
	const phoneWithCode = countryCode + phoneClean
	
	if (isValidPhoneNumber(phoneWithCode)) {
		const phoneNumber = parsePhoneNumber(phoneWithCode, country)
		
		localValue.value = phoneNumber.formatInternational()
		local.value = cleanPhoneCode(phoneNumber.formatInternational())
	}
})

</script>

<template>
	<UInput
		v-model="local"
		v-bind="$attrs"
		type="phone"
		class="bg-white"
		:color="color"
		:size="size"
		:placeholder="placeholder"
		:leading="true"
		:ui="uiLocal"
		:autofocus="autofocus"
	>
		<template #leading>
			{{ countryCode }}
		</template>
	</UInput>
</template>