import * as Yup from 'yup'
import parsePhoneNumber, {
  isPossiblePhoneNumber,
  isValidNumberForRegion,
} from 'libphonenumber-js'
import type { CountryCode } from 'libphonenumber-js'

declare module 'yup' {
  export interface StringSchema {
    /**
     * Check for phone number validity.
     *
     * @param {String} [countryCode=IN] The country code to check against.
     * @param {Boolean} [strict=false] How strictly should it check.
     * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
     */
    phone(
      countryCode?: string,
      strict?: boolean,
      errorMessage?: string
    ): StringSchema;
  }
}

const YUP_PHONE_METHOD = 'phone'
const CLDR_REGION_CODE_SIZE = 2

const isValidCountryCode = (countryCode: any): boolean =>
  typeof countryCode === 'string' &&
  countryCode.length === CLDR_REGION_CODE_SIZE

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function yupPhone(
  countryCode: CountryCode = 'RU',
  strict: boolean = false,
  errorMessage: string = ''
) {
  const errMsg =
    errorMessage
      ? errorMessage
      : isValidCountryCode(countryCode)
        ? `\${path} must be a valid phone number for region ${countryCode}`
        : '${path} must be a valid phone number.'

  // @ts-ignore
  return this.test(YUP_PHONE_METHOD, errMsg, (value: string) => {
    if (!isValidCountryCode(countryCode)) {
      countryCode = 'RU'
      strict = false
    }

    try {
      if (!isPossiblePhoneNumber(value, countryCode)) {
        return false
      }

      const phoneNumber = parsePhoneNumber(value, countryCode)
      if (!phoneNumber) {
        return false
      }

      return isValidNumberForRegion(value, countryCode)
    } catch {
      return false
    }
  })
})
